import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Home from '../components/home'
import Who from '../components/who'
import What from '../components/what'
import Services from '../components/services'
import Assistance from '../components/assistance'
import Connect from '../components/connect'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Home />
    <Who />
    <What />
    <Services />
    <Assistance />
    <Connect />
  </Layout>
)

export default IndexPage
