import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-scroll'

const Container = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 1);
    padding: 1.5rem var(--gutter-s);
    color: var(--white);
    @media (min-width: 768px) {
        padding: 1rem var(--gutter-l);
    }
`

const Copyright = styled.p`
    font-size: 0.6rem;
    letter-spacing: 1px;
    color: var(--mine-shaft);
    font-weight: 700;
    color: var(--white);
`

const Social = styled.div`
    display: flex;
    padding: 1rem 0;
    a {
        display: inline-block;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid var(--white);
        transition: 250ms ease-out;
        margin: 0 0.5rem;
        svg {
            width: 45%;
            path {
                transition: 250ms ease-out;
                fill: var(--white);
            }
        }
        &:hover {
            background-color: var(--white);
            svg {
                path {
                    fill: var(--black);
                }
            }
        }
    }
`

const Menu = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    li {
        list-style: none;
        padding: 0 1rem;
        a {
            display: inline-block;
            font-size: 0.90rem;
            padding: 0.75rem 0;
        }
    }
    @media (min-width: 768px) {
        flex-direction: row;
        li {
            a {
                position: relative;
                cursor: pointer;
                &:after {
                    content: '';
                    position: absolute;
                    width: 0%;
                    height: 2px;
                    background-color: var(--roman);
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: 250ms ease-out;
                }
                &:hover {
                    &:after {
                        width: 100%;
                    }
                }
            }
        }
    }
`

const Line = styled.hr`
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    margin: 20px 0;
`

const Footer = () => {
    return (
        <Container>
            <Social>
                <a href="mailto:invest@globalx.co.za?Subject=I%20am%20ready%20to%20invest" target="_top">
                    <svg viewBox="0 0 24 24"><path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"/></svg>
                </a>
            </Social>
            <Menu>
                <li>
                    <Link to="home" spy={true} smooth={true} duration={500} >Home</Link>
                </li>
                <li>
                    <Link to="who" spy={true} smooth={true} duration={500} >Who we are</Link>
                </li>
                <li>
                    <Link to="what" spy={true} smooth={true} duration={500} >What we do</Link>
                </li>
                <li>
                    <Link to="connect" spy={true} smooth={true} duration={500} >Connect with us</Link>
                </li>
            </Menu>
            <Line />
            <Copyright>
                &copy; 2019 COPYRIGHT GLOBAL X. ALL RIGHTS RESERVED.
            </Copyright>
        </Container>
    );
}
 
export default Footer;