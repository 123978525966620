import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Link } from 'react-scroll'

import logo from '../images/logo.png'

const Container = styled.header`
    z-index: 9;
    width: 100%;
    padding: var(--gutter-s);
    display: grid;
    grid-template: auto auto / 1fr 1fr;
    @media (min-width: 768px) {
        grid-template: auto / 200px 1fr;
        padding: 2rem var(--gutter-l);
    }
`

const Logo = styled.div`
    img {
        max-width: 200px;
    }
`

const Icon = styled.div`
    justify-self: end;
    display: flex;
    align-items: center;
    svg {
        path {
            fill: var(--black);
        }
    }
    @media (min-width: 768px) {
        display: none;
        svg {}
    }
`

const Menu = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 0;
    height: 0;
    overflow: hidden;
    transition: 250ms ease-out;
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    padding-top: 1rem;
    li {
        list-style: none;
        a {
            color: var(--black);
            font-size: 1rem;
            padding: 1.5rem 0;
            display: inline-block;
            font-weight: 700;
        }
    }
    &.open {
        min-height: 50vh;
    }
    @media (min-width: 768px) {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
        justify-self: end;
        flex-direction: row;
        height: auto;
        min-height: auto;
        padding-top: 0rem;
        li {
            padding: 0 1rem;
            a {
                position: relative;
                font-size: 0.90rem;
                padding: 0.5rem 0;
                cursor: pointer;
                &:after {
                    content: '';
                    position: absolute;
                    width: 0%;
                    height: 2px;
                    background-color: var(--roman);
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: 250ms ease-out;
                }
                &:hover {
                    &:after {
                        width: 100%;
                    }
                }
            }
            &:last-child {
                padding: 0 0 0 1rem;
            }
        }
    }
`

class Navbar extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false
        }
        this.frameId = null
    }
    componentDidMount() {
        window.addEventListener('resize', this.onResize, false)
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize, false)
        window.cancelAnimationFrame(this.frameId)
    }
    toggleMenu = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }
    breakpointCollapse = () => {
        if (document.body.getBoundingClientRect().width < 768) {
            this.menu.classList.remove('open')
        }
    }
    onResize = () => {
        this.frameId = window.requestAnimationFrame(this.breakpointCollapse)
    }
    render() { 
        let { isOpen } = this.state;
        return (
            <Container name="home">
                <Logo>
                    <img src={logo} alt="Submitt App"/>
                </Logo>
                <Icon onClick={this.toggleMenu}>
                    <svg width="24" height="24" viewBox="0 0 24 24">
                        <path d="M12 18c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"/>
                    </svg>
                </Icon>
                <Menu className={isOpen ? 'open' : null} ref={ref => {this.menu = ref}}>
                    <li>
                        <Link to="who" spy={true} smooth={true} duration={500} >Who we are</Link>
                    </li>
                    <li>
                        <Link to="what" spy={true} smooth={true} duration={500} >What we do</Link>
                    </li>
                    <li>
                        <Link to="connect" spy={true} smooth={true} duration={500} >Connect with us</Link>
                    </li>
                </Menu>
            </Container>
        )
    }
}
 
export default Navbar

/**
 * Props
 * 
 */