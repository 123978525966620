import React, { PureComponent } from 'react'
import styled from 'styled-components'


const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--gutter-l) 0;
    position: relative;
    h1 {
        position: absolute;
        color: rgba(0, 0, 0, 0.05);
        left: 5px;
        font-size: 3rem;
        bottom: 20%;
        transform: rotate(-90deg);
        transform-origin: center left;
    }
    @media (min-width: 768px) {
        padding: var(--gutter-l) 0;
        h1 {
            font-size: 10rem;
            left: 15px;
            bottom: 0%;
        }
    }
`

const Globe = styled.div`
    width: 250px;
    height: 250px;
    position: relative;
    @media (min-width: 768px) {
        width: 500px;
        height: 500px;
    }
`

const Orbit = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #f2f2f2;
    position: absolute;
    .inner {
        width: 60%;
        height: 60%;
        border: 1px solid #f2f2f2;
        border-radius: 50%;
        top: 20%;
        left: 20%;
        position: absolute;
    }
`

const Service = styled.span`
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    background-color: #f2f2f2;
    &:after {
        position: absolute;
        top: -3px;
        font-size: 0.65rem;
        letter-spacing: 1px;
        /* dynamic content */
        left: 125%;
    }
    @media (min-width: 768px) {
        &:after {
            font-size: 0.9rem;
            width: 200px;
        }
    }

    &.one {
        top: calc(0% - 8px);
        left: calc(50% - 8px);
        &:after {
            content: 'Working Capital';
        }
    }
    &.two {
        right: calc(0% - 8px);
        top: calc(50%);
        &:after {
            content: 'Growth & Expansion Capital';
        }
    }
    &.three {
        left: calc(0% - 8px);
        top: calc(50%);
        &:after {
            content: 'Mergers & Acquisitions';
        }
    }
    &.four {
        left: calc(50%);
        bottom: calc(0% - 8px);
        &:after {
            content: 'Restructuring';
        }
    }
    &.five {
        left: calc(14% - 4px);
        top: calc(14% - 4px);
        &:after {
            content: 'Network Facilitations';
        }
    }
    &.six {
        right: calc(14% - 4px);
        top: calc(14% - 4px);
        &:after {
            content: 'Business Analytics & Reviews';
        }
    }
    &.seven {
        left: calc(14% - 4px);
        bottom: calc(14% - 4px);
        &:after {
            content: 'Back Office Support Services';
        }
    }
    &.eight {
        right: calc(14% - 4px);
        bottom: calc(14% - 4px);
        &:after {
            content: 'Strategy';
        }
    }
`

class Assistance extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() { 
        return (
            <Container name="assistance"> 
                <h1 className="font-bold">Services</h1>
                <Globe>
                    <Orbit>
                        <div className="inner"></div>
                    </Orbit>
                    <Service className="one" />
                    <Service className="two" />
                    <Service className="three" />
                    <Service className="four" />
                    <Service className="five" />
                    <Service className="six" />
                    <Service className="seven" />
                    <Service className="eight" />
                </Globe>
            </Container> 
        );
    }
}
 
export default Assistance;