import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: grid;
    grid-template: auto auto / 1fr;
    padding: var(--gutter-s);
    @media (min-width: 768px) {
        grid-template: 1fr / 35vw 1fr;
        padding: calc(2 * var(--gutter-l)) var(--gutter-l) var(--gutter-l);
    }
`

const Heading = styled.div`
    margin-bottom: 2rem;
    h1 {
        letter-spacing: 1px;
        font-size: 2rem;
    }
    hr {
        width: 100px;
        height: 1px;
        background-color: var(--roman);
        border: none;
        margin: 1rem 0;
    }
    @media (min-width: 768px) {
        h1 {
            font-size: 3rem;
        }
    }
`

const Content = styled.div`
    /* padding: var(--gutter-l) var(--gutter-s); */
    p {
        margin-bottom: 2rem;
        line-height: 1.5rem;
        font-size: 0.9rem;
        letter-spacing: 1px;
    }
    @media (min-width: 768px) {
        /* padding: var(--gutter-l) var(--gutter-l); */
    }
`

const Button = styled.a`
    border: 1px solid black;
    background-color: rgba(0,0,0,1);
    border-radius: 4px;
    color: white;
    padding: 0.75rem 1rem;
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 1px;
    transition: 300ms ease-out;
    &:hover {
        background-color: rgba(0,0,0,0);
        color: black;
    }
`

const Who = () => {
    return (
        <Container name="who">
            <Heading>
                <h1 className="font-bold">
                    Who
                </h1>
                <hr/>
                <h1 className="font-bold">
                    We Are
                </h1>
            </Heading>
            <Content>
                <p>
                    Global X is a privately owned independent Investment House situated in
                    South Africa with a global footprint operating in a more exclusive and higher
                    echelon than most Investment houses which is self-funded with readily available
                    capital. 
                </p>
                <p>
                    Global X provides capital, business reviews and multitude of other
                    facilitation services to a broad range of industries globally with a significant
                    network of specialists and facilitators. Global X has subsidiaries in various industries
                    from Construction, Mining, Pharmaceutical, Health, Chemical and many more with hands
                    on management thus able to provide in-depth solutions and facilitations.
                </p>
                <Button href="mailto:invest@globalx.co.za?Subject=I%20am%20ready%20to%20invest" target="_top">
                    Invest Now
                </Button>
            </Content>
        </Container>
    );
}
 
export default Who