import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Swiper } from 'swiper/dist/js/swiper.esm'

import logistics from '../images/services/logistics.jpg'
import mining from '../images/services/mining.jpg'
import art from '../images/services/art.jpg'
import hospitality from '../images/services/hospitality.jpg'
import chemical from '../images/services/chemical.jpg'
import medical from '../images/services/medical.jpg'

const DATA = [
    {
        heading: 'LOGISTICS',
        description: 'Global X provides various solutions and facilitations in the logistical industry from transport, secured transport, back office support solutions, mining & others.'
    },
    {
        heading: 'MINING',
        description: 'Global X provides significant network connections within the Mining Industry which includes mines, refineries, buyers & sellers throughout the entire value chain may it be gold, platinum or coal.'
    },
    {
        heading: 'COLLECTABLES',
        description: 'Through Global X’s network, it is able to connect buyers & sellers for highly sought-after collectable or high value items from watches, precious stones, precious metals, art and others. Global X offers this service to its select clientele as an added benefit of being a customer of Global X.'
    },
    {
        heading: 'HOSPITALITY',
        description: 'Global X is seen as a leading expert in the Hospitality industry and is able to provide in-depth solutions and facilitations to our clientele from grassroots to Board level assistance. Global X through its subsidiaries or network is able to provide designs services, interior design services to custom furniture and matrasses as an example.'
    },
    {
        heading: 'CHEMICALS',
        description: 'Through Global X’s subsidiaries or network it has a widespread offering from Paint Manufacturing to chemical compounds including formulations and retail solutions with over 60yrs experience in this niche market.'
    },
    {
        heading: 'MEDICAL',
        description: 'Global X is able to assist our select clientele with a multitude of different solutions and facilitation within the Pharmaceutical & Health industries as an entity with exposure in drug research & development, supplement manufacturing including marketing, manufacturing, packaging and analytics. Global X is able to assist with a wide range of solutions within this industry.'
    },
]

const Container = styled.div`
    display: grid;
    grid-template: auto auto auto auto / calc(100vw - (2 * var(--gutter-s)));
    padding: var(--gutter-l) var(--gutter-s);
    position: relative;
    overflow: hidden;
    @media (min-width: 768px) {
        padding: var(--gutter-l) var(--gutter-l);
        grid-template: auto auto / 35vw 1fr 2fr minmax(50px, 5vw);
    }
`

const Heading = styled.div`
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    position: absolute;
    right: -30px;
    top: -75px;
    h1 {
        text-align: right;
        letter-spacing: 1px;
        font-size: 3rem;
        color: rgba(0, 0, 0, 0.05);
    }
    @media (min-width: 768px) {
        grid-row: 1 / 2;
        grid-column: 1 / 5;
        right: -100px;
        top: -50px;
        h1 {
            font-size: 6rem;
        }
    }
`

const Content = styled.div`
    background-color: #f2f2f2;
    padding: var(--gutter-s) var(--gutter-s) var(--gutter-s) var(--gutter-s);
    grid-row: 4 / 5;
    grid-column: 1 / 2;
    h2 {
        letter-spacing: 1px;
    }
    p {
        line-height: 1.5rem;
        font-size: 0.9rem;
        letter-spacing: 1px;
    }
    hr {
        width: 100px;
        height: 1px;
        background-color: var(--roman);
        border: none;
        margin: 1rem 0;
    }
    @media (min-width: 768px) {
        grid-row: 2 / 3;
        grid-column: 1 / 3;
        padding: var(--gutter-m) 20vw var(--gutter-m) var(--gutter-m);
        hr {
            margin: 2rem 0;
        }
    }
`

const SwiperContainer = styled.div`
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    .swiper-container {
        width: 100%;
        height: 400px;
        overflow: hidden;
        .swiper-wrapper {
            /* height: 100%; */
            .swiper-slide {
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                }
            }
        }
    }
    @media (min-width: 768px) {
        grid-row: 2 / 3;
        grid-column: 2 / 4;
        padding: 40px 0;
    }
`

const Controls = styled.div`
    grid-row: 3 / 4;
    grid-column: 1 / 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 1rem;
    .pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        hr {
            height: 1px;
            width: 50px;
            background-color: var(--roman);
            border: none;
            margin: 0 10px;
        }
        span {
            font-size: 0.75rem;
        }
    }
    .arrows {
        display: flex;
        flex-direction: row;
        span {
            border: 1px solid black;
            height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0.25;
            transition: 250ms ease-out;
            &:first-child {
                margin-right: 1rem;
            }
            &.active {
                opacity: 1;
            }
        }
        svg {}
    }
    @media (min-width: 768px) {
        grid-row: 2 / 3;
        grid-column: 4 / 5;
        padding: 40px 1rem;
        flex-direction: column;
        .pagination {
            flex-direction: column;
            hr {
                height: 50px;
                width: 1px;
                margin: 5px 0;
            }
        }
        .arrows {
            flex-direction: column;
            cursor: pointer;
            span {
                &:first-child {
                    margin-right: 0;
                    margin-bottom: 1rem;
                }
            }
        }
    }
`

class What extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeSlide: 0
        }
        this.swiper = null
    }
    componentDidMount() {
        this.swiper = new Swiper('.swiper-container', {
            speed: 500,
            direction: 'vertical',
            on: {
                transitionStart: () => {
                    let swiperIndex = this.swiper.activeIndex
                    this.setState({ activeSlide: swiperIndex })
                },
            },
        })
    }

    renderHeading = () => {
        return (
            <h2 className="font-bold"> {DATA[this.state.activeSlide].heading} </h2>
        )
    }

    renderDescription = () => {
        return (
            <p> {DATA[this.state.activeSlide].description} </p>
        )
    }

    slidePrev = () => {
        if (this.swiper) {
            this.swiper.slidePrev()
        }
    }

    slideNext = () => {
        if (this.swiper) {
            let { activeSlide } = this.state
            this.swiper.slideTo(activeSlide + 1)
        }
    }

    render() {
        let { activeSlide } = this.state
        return (
            <Container name="what">
                <Heading>
                    <h1 className="font-bold">
                        What We Do
                    </h1>
                </Heading>
                <Content>
                    {this.renderHeading()}
                    <hr/>
                    {this.renderDescription()}
                </Content>
                <SwiperContainer>
                    <div className="swiper-container">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <img src={mining} alt="Global X"/>
                            </div>
                            <div className="swiper-slide">
                                <img src={logistics} alt="Global X"/>
                            </div>
                            <div className="swiper-slide">
                                <img src={art} alt="Global X"/>
                            </div>
                            <div className="swiper-slide">
                                <img src={hospitality} alt="Global X"/>
                            </div>
                            <div className="swiper-slide">
                                <img src={chemical} alt="Global X"/>
                            </div>
                            <div className="swiper-slide">
                                <img src={medical} alt="Global X"/>
                            </div>
                        </div>
                    </div>
                </SwiperContainer>
                <Controls>
                    <div className="pagination">
                        <span>{`0${activeSlide + 1}`}</span>
                        <hr/>
                        <span>{`0${DATA.length}`}</span>
                    </div>
                    <div className="arrows">
                        <span className={activeSlide > 0 ? 'active' : null} onClick={this.slidePrev}>
                            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M23.245 20l-11.245-14.374-11.219 14.374-.781-.619 12-15.381 12 15.391-.755.609z"/></svg>
                        </span>
                        <span className={activeSlide < (DATA.length - 1) ? 'active' : null} onClick={this.slideNext}>
                            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M23.245 4l-11.245 14.374-11.219-14.374-.781.619 12 15.381 12-15.391-.755-.609z"/></svg>
                        </span>
                    </div>
                </Controls>
            </Container>
        );
    }
}
 
export default What