import React from 'react';
import styled from 'styled-components'
import { Link } from 'react-scroll'

import hero from '../images/hero.jpg'

const Container = styled.section`
    display: grid;
    grid-template: auto auto / 1fr;
    min-height: calc(100vh - 110px);
    padding: 0 var(--gutter-s);
    @media (min-width: 768px) {
        grid-template: 1fr / 25vw 10vw 10vw 1fr;
        padding: 0 var(--gutter-l);
    }
`

const Intro = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    .inner {
        background-color: var(--white);
        padding: var(--gutter-s) var(--gutter-s) var(--gutter-s) 0;
        h1, p {
            letter-spacing: 1px;
            line-height: 150%;
        }
        h1 {
            margin-bottom: 1rem;
            font-size: 1.35rem;
        }
        p {
            font-size: 0.9rem;
            margin-bottom: 1rem;
        }
        svg {
            width: 48px;
            path {
                fill: var(--roman);
            }
        }
    }
    @media (min-width: 768px) {
        grid-row: 1 / 2;
        grid-column: 1 / 3;
        .inner {
            padding: var(--gutter-l) var(--gutter-s) var(--gutter-l) 0;
            h1, p {
                margin-bottom: 2rem;
            }
            h1 {
                font-size: 2rem;
            }
            svg {
                cursor: pointer;
            }
        }
    }
`

const Hero = styled.div`
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
    @media (min-width: 768px) {
        grid-row: 1 / 2;
        grid-column: 2 / 5;
    }
`

const Home = () => {
    return (
        <Container>
            <Hero>
                <img src={hero} alt="Global X"/>
            </Hero>
            <Intro>
                <div className="inner">
                    <h1 className="font-semi-bold">
                        Creating a better future <br />
                        for you, now.
                    </h1>
                    <p>
                        Global X is a botique investment house looking out for the creation of wealth amongst its partners.
                    </p>
                    <Link to="who" spy={true} smooth={true} duration={500}>
                        <svg viewBox="0 0 24 24">
                            <path d="M11 21.883l-6.235-7.527-.765.644 7.521 9 7.479-9-.764-.645-6.236 7.529v-21.884h-1v21.883z"/>
                        </svg>
                    </Link>
                </div>
            </Intro>
        </Container>
    );
}
 
export default Home;