import React from 'react';
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--gutter-s);
    p {
        letter-spacing: 1px;
        font-size: 0.9rem;
        line-height: 150%;
        text-align: center;
        max-width: 100%;
    }
    @media (min-width: 768px) {
        padding: var(--gutter-l);
        p {
            max-width: 60%;
        }
    }
`

const Services = () => {
    return (
        <Container>
            <p>
                Due to Global X’s wide network of high nett worth individuals, selected entities and selected facilitators, Global X is able to operate in a very niche market for a select clientele and should Global X elect not to invest itself it can facilitate and or assist in structuring various deals for its select clientele to its wide network.
            </p>
        </Container>
    );
}
 
export default Services;