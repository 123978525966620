import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: grid;
    grid-template: auto / auto auto;
    padding: var(--gutter-s);
    align-items: center;
    background-color: #f2f2f2;
    @media (min-width: 768px) {
        grid-template: 1fr / 35vw 1fr;
        align-items: center;
        padding: calc(1 * var(--gutter-l)) var(--gutter-l) var(--gutter-l);
    }
`

const Heading = styled.div`
    margin-bottom: 2rem;
    h1 {
        letter-spacing: 1px;
        font-size: 2rem;
    }
    hr {
        width: 100px;
        height: 1px;
        background-color: var(--roman);
        border: none;
        margin: 1rem 0;
    }
    @media (min-width: 768px) {
        h1 {
            font-size: 3rem;
        }
    }
`

const Contact = styled.div`
    p, a {
        font-size: 0.9rem;
        letter-spacing: 1px;
    }
    p {
        margin-bottom: 1.5rem;
        &:nth-child(2) {
            margin-bottom: 0.25rem;
        }
    }
    a {
        position: relative;
        padding-bottom: 0.35rem;
        &:after {
            content: '';
            position: absolute;
            width: 0%;
            height: 1px;
            background-color: var(--roman);
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            transition: 250ms ease-out;
        }
        &:hover {
            &:after {
                width: 100%;
            }
        }
    }
`

const Connect = () => {
    return (
        <Container name="connect">
            <Heading>
                <h1 className="font-bold">
                    Connect
                </h1>
                <hr/>
                <h1 className="font-bold">
                    With Us
                </h1>
            </Heading>
            <Contact>
                <p>Lukas <span className="font-bold"> van der Westhuizen</span> </p>
                <p>+27 (0) 84 681 0128</p>
                <a href="mailto:lukas@globalx.co.za?subject=Hello">lukas@globalx.co.za</a>
            </Contact>
        </Container>
    );
}
 
export default Connect